import axios from 'axios';
import { toast } from 'sonner';

/*** Api hostname ***/
export const apiHostName = process.env.REACT_APP_HOST_NAME || '';

/*** Axios settings ***/
// base api url
export const api = axios.create({
  baseURL: `${apiHostName}/api/v1/`,
});

// массив роутов, для которых надо отправлять токен
const sendTokenEndPointsArr = [
  'profile/',
  'history/',
  'birthday/',
  'get_my_id/',
  'make_as_training/',
  'remove_as_training/',
  'reviews/',
  'pay/',
  'freeze_product_on/',
  'freeze_product_off/',
  'available_freeze_duration/',
  'freeze_date_end/',
  'has-trial-training/',
  'has-available-balance/',
  'klass/',
  'change-password/',
  'product/',
  'type-training/',
];

// перехват запросов до того, как они будут "then" или "catch"
// если есть токен, то добавляем его ко всем запросам
api.interceptors.request.use((config) => {
  const isToken = localStorage.getItem('access_token');
  const configUrlRequest = `${config.url.split('/')[0]}/`;
  // запрос на reviews без параметров
  const reviewsNoParams = configUrlRequest === 'reviews/' && !config.url.split('/')[1];

  if ((isToken && sendTokenEndPointsArr.includes(configUrlRequest)) || reviewsNoParams) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
});

// перехват ответов
api.interceptors.response.use(
  (config) => config, // любой код состояния, находящийся в диапазоне 2xx,
  async (error) => {
    // любые коды состояния, выходящие за пределы диапазона 2xx
    const originalRequest = error.config; // первоначальный запрос, который выдал ошибку
    console.log(error);
    if (error.response.status === 500) {
      toast.error('Произошла ошибка на сервере, пожалуйста, обратитесь к администратору');
    }
    // проверка на 401 статус
    if (error.response.status === 401 && error.config && error.config.url !== 'token/refresh/') {
      if (error.config.url === 'token/') {
        throw error;
      }
      if (!originalRequest._isRetry) {
        originalRequest._isRetry = true;

        try {
          const refreshToken = localStorage.getItem('refresh_token');
          const response = await api.post('token/refresh/', { refresh: refreshToken });

          localStorage.setItem('access_token', response.data.access);

          return api.request(originalRequest);
        } catch (e) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          throw e;
        }
      } else {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      }
    }
    throw error;
  }
);
